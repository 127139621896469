import { createDateTemplate } from "./time";

// Extended Date prototype is locate at smore/app/src/_types/globals.d.ts

Date.prototype.toFormat = function (niceFormat: string): string {
  return createDateTemplate(niceFormat)(this);
};

Date.prototype.toMonth = function (format: "long" | "short" = "long"): string {
  return this.toLocaleString("en-us", { month: format });
};

Date.prototype.toMonthAndYear = function (format: "long" | "short" = "long"): string {
  return this.toLocaleString("en-us", { month: format, year: "numeric" });
};

Date.prototype.toYear = function (): number {
  return this.getFullYear();
};

Date.prototype.toShortDate = function (): string {
  return `${this.toMonth("short")} ${this.getDate()}, ${this.toYear()}`;
};

Date.prototype.toDayAndMonth = function (): string {
  return `${this.toMonth("short")} ${this.getDate()}`;
};

interface DatePrettifierOptions {
  // Support future dates?
}
Date.prototype.prettify = function (options?: DatePrettifierOptions) {
  const now = new Date();
  var day = this.getDate();
  var month = this.toLocaleString("en-us", { month: "long" });
  var year = this.getFullYear();

  if (day === now.getDate()) {
    const hDiff = now.getHours() - this.getHours();
    if (hDiff === 0) {
      const mDiff = now.getMinutes() - this.getMinutes();
      if (mDiff === 0) {
        return "just now";
      }
      if (mDiff === 1) {
        return "a minute ago";
      }
      return `${mDiff} minutes ago`;
    }
    if (hDiff === 1) {
      return "1 hour ago";
    }
    return `${hDiff} hours ago`;
  }

  return `${month} ${day}, ${year}`;
};

Date.prototype.addSeconds = function (seconds: number) {
  this.setSeconds(this.getSeconds() + seconds);
  return this;
};

Date.prototype.addMinutes = function (minutes: number) {
  this.setMinutes(this.getMinutes() + minutes);
  return this;
};

Date.prototype.addHours = function (hours: number) {
  this.setHours(this.getHours() + hours);
  return this;
};

Date.prototype.addDays = function (days: number) {
  this.setDate(this.getDate() + days);
  return this;
};

Date.prototype.addWeeks = function (weeks: number) {
  this.addDays(weeks * 7);
  return this;
};

Date.prototype.clone = function () {
  return new Date(this.getTime());
};
